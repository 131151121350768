export const DATA_MIXIN = {
  methods: {
    $_dataMixin_createSeriesFromCSV(csvData) {
      let ser = [];
      let serLen = csvData[0].length - 1;
      const xtype = this.chartConfig.xaxis.type;

      if (this.isCartesianChart) {
        if (this.chartType === "rangeBar" || this.chartType === "bubble") {
          let data = [];
          for (let j = 1; j < csvData.length; j++) {
            if (
              typeof csvData[j][1] === "undefined" ||
              typeof csvData[j][2] === "undefined"
            ) {
              return {
                error: "Chart data is not in valid format.",
              };
            }

            if (this.chartType === "rangeBar") {
              data.push({
                x: csvData[j][0],
                y: [parseFloat(csvData[j][1]), parseFloat(csvData[j][2])],
              });
            } else {
              data.push({
                x:
                  xtype === "category"
                    ? csvData[j][0]
                    : parseFloat(csvData[j][0]),
                y: parseFloat(csvData[j][1]),
                z: parseFloat(csvData[j][2]),
              });
            }
          }
          ser.push({
            name: csvData[0][1] ? csvData[0][1] : `series-${1}`,
            data,
          });
        } else {
          for (let i = 0; i < serLen; i++) {
            let data = [];
            for (let j = 1; j < csvData.length; j++) {
              data.push({
                x: csvData[j][0],
                y: parseFloat(csvData[j][i + 1]),
              });
            }

            ser.push({
              name: csvData[0][i + 1] ? csvData[0][i + 1] : `series-${i + 1}`,
              data,
            });
          }
        }
      } else {
        let labels = [];
        let series = [];

        for (let i = 0; i < serLen; i++) {
          for (let j = 1; j < csvData.length; j++) {
            labels.push(csvData[j][0]);
            series.push(parseFloat(csvData[j][i + 1]));
          }
        }

        ser = {
          labels,
          series,
        };
      }

      return ser;
    },
    $_dataMixin_createRecordsFromSeries() {
      this.columns = [];
      this.records = [];
      this.columns.push({ key: "x", name: "Category" });

      if (this.isCartesianChart) {
        const xtype = this.chartConfig.xaxis.type;

        for (let i = 0; i < this.chartConfig.series.length; i++) {
          this.columns.push({
            key: "series-" + (i + 1),
            name: this.chartConfig.series[i].name
              ? this.chartConfig.series[i].name
              : "Series-" + (i + 1),
          });

          if (this.chartType === "rangeBar") {
            this.columns[1].name = "Start";
            this.columns.push({
              key: "series-" + (i + 2),
              name: "End",
            });
          }

          if (this.chartType === "bubble") {
            this.columns.push({
              key: "series-" + (i + 2),
              name: "Bubble Size",
            });
          }
        }

        const horzChart =
          this.chartType === "rangeBar" ||
          (this.chartType === "bar" &&
            this.chartConfig.plotOptions.bar.horizontal);
        if (!horzChart) {
          this.chartConfig.series.forEach((s) => {
            s.data.forEach((d, rowIndex) => {
              this.$set(this.records, rowIndex, {
                x:
                  xtype === "datetime"
                    ? dayjs(d.x).format("YYYY-MM-DD HH:mm")
                    : d.x,
              });
            });
          });
        } else {
          this.chartConfig.series.forEach((s) => {
            s.data.forEach((d, rowIndex) => {
              this.$set(this.records, rowIndex, { x: d.x });
            });
          });
        }

        if (this.chartType === "rangeBar") {
          this.chartConfig.series.forEach((s, sIndex) => {
            s.data.forEach((d, rowIndex) => {
              this.records[rowIndex][`series-${sIndex + 1}`] =
                xtype === "datetime"
                  ? dayjs(d.y[0]).format("YYYY-MM-DD HH:mm")
                  : d.y[0];
              this.records[rowIndex][`series-${sIndex + 2}`] =
                xtype === "datetime"
                  ? dayjs(d.y[1]).format("YYYY-MM-DD HH:mm")
                  : d.y[1];
            });
          });
        } else if (this.chartType === "bubble") {
          const xtype = this.chartConfig.xaxis.type;
          this.chartConfig.series.forEach((s, sIndex) => {
            s.data.forEach((d, rowIndex) => {
              this.records[rowIndex][`series-${sIndex + 1}`] = d.y;
              this.records[rowIndex][`series-${sIndex + 2}`] = d.z;
            });
          });
        } else {
          this.chartConfig.series.forEach((s, sIndex) => {
            s.data.forEach((d, rowIndex) => {
              this.records[rowIndex][`series-${sIndex + 1}`] = d.y;
            });
          });
        }
      } else {
        this.columns.push({ key: "y", name: "Value" });

        this.records = this.chartConfig.series.map((s, sI) => {
          return {
            x: this.chartConfig.labels[sI],
            y: s,
          };
        });
      }
    },
  },
};
