<template>
  <div id="data">
    <div class="creation">
      <el-button type="primary" @click="onRowCreate">
        <span class="d-flex d-flex-align-center">
          <img class="mr2" :src="`${publicPath}assets/icons/ico-row.svg`" alt />
          <span>+ <small>ROW</small> </span>
        </span>
      </el-button>
      <el-button
        type="primary"
        :disabled="!isCartesianChart || isRangeChart || isBubbleChart"
        @click="onColCreate"
      >
        <span class="d-flex d-flex-align-center">
          <img
            class="mr2"
            :src="`${publicPath}assets/icons/ico-column.svg`"
            alt
          />
          <span>+ <small>COLUMN</small></span>
        </span>
      </el-button>
      <el-button type="success" @click="selectCSVfile">
        <i class="el-icon-upload"></i> CSV
        <input
          @change="uploadCSV"
          type="file"
          style="display:none;"
          ref="attach"
          name="attach"
          accept=".csv"
        />
      </el-button>
    </div>

    <el-dialog
      title="Change the name of the series"
      :visible.sync="showSeriesNameDialog"
      :append-to-body="true"
      width="30%"
    >
      <el-form @submit.native.prevent ref="form">
        <el-form-item label="Edit Column Name">
          <el-input
            @keyup.enter.native="saveSeriesName"
            v-model="editSeriesName"
          ></el-input>
        </el-form-item>
        <el-form-item label="Delete Column">
          <el-button type="danger" @click="removeSeries">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveSeriesName">OK</el-button>
        <el-button @click="onCloseSeriesNameDialog">Cancel</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Update range"
      width="30%"
      :append-to-body="true"
      :destroy-on-close="true"
      :visible.sync="showTimelineValueDialog"
    >
      <el-form @submit.native.prevent ref="form-timeline-values">
        <el-date-picker
          v-model="timelineValues"
          type="datetimerange"
          range-separator="To"
          :clearable="false"
          start-placeholder="Start date-time"
          end-placeholder="End date-time"
        ></el-date-picker>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTimelineValue">OK</el-button>
        <el-button @click="onCloseTimelineValueDialog">Cancel</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Update Datetime value"
      width="30%"
      :append-to-body="true"
      :visible.sync="showXaxisDatetimeDialog"
    >
      <el-form @submit.native.prevent ref="form">
        <el-date-picker
          v-model="xDatetimeValue"
          type="datetime"
          :clearable="false"
          placeholder="Select Date/time"
        ></el-date-picker>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savexDatetimeValue">OK</el-button>
        <el-button @click="onClosexDatetimeValueDialog">Cancel</el-button>
      </span>
    </el-dialog>

    <div v-if="columns" class="demo-grid middle">
      <c-grid font="14px Roboto" :theme="userTheme" :data="records">
        <c-grid-input-column
          v-for="(column, index) in columns"
          :key="index"
          :width="columnWidth"
          @click-cell="onClickCell($event, index)"
          @changed-value="changeDataValue"
          :readonly="
            (index > 0 && isRangeChart) ||
              (index === 0 && xaxisType === 'datetime' && !isRangeChart)
          "
          :input-type="`${index === 0 ? 'text' : 'number'}`"
          :field="column.key"
          >{{ column.name }}</c-grid-input-column
        >
        <c-grid-button-column
          v-if="isCartesianChart && series.length === 1"
          caption="⚙"
          width="10.7%"
          @click-cell="onClickDataPointConfig($event)"
        />
        <c-grid-button-column
          caption="×"
          width="10.7%"
          @click-cell="onClickDeleteRow($event)"
        />
      </c-grid>
    </div>

    <el-dialog
      title="DataPoint Color"
      width="30%"
      v-if="series.length === 1"
      :append-to-body="false"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="showDataPointConfigDialog"
    >
      <div>
        <extended-el-color-picker
          @change="dataPointColorChanged"
          v-model="dataPointFillColor"
        ></extended-el-color-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateDataPointConfig">OK</el-button>
        <el-button @click="onCloseDataPointConfig">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DATA_MIXIN } from "./utils/data.mixins";
import { getDate } from "@/utils";

export default {
  mixins: [DATA_MIXIN],
  data() {
    return {
      series: [],
      xaxisType: "category",
      isRangeChart: false,
      isBubbleChart: false,
      csvFile: null,
      columns: [],
      records: [],
      editSeriesName: null,
      timelineValues: "",
      xDatetimeValue: "",
      dataPointFillColor: "",
      showSeriesNameDialog: false,
      showXaxisDatetimeDialog: false,
      showTimelineValueDialog: false,
      showDataPointConfigDialog: false,
      clickedCellInfo: null,
      userTheme: {
        color: "#fefefe",
        defaultBgColor: "#343E59",
        underlayBackgroundColor: "#343E59",
        borderColor: "#24272B",
        highlightBorderColor: "#dce0f0",
        highlightBgColor: "#24272B",
        checkbox: {
          checkBgColor: "#35495e",
          borderColor: "#35495e",
        },
        button: {
          color: "#fff",
          bgColor: "#24272b",
        },
      },
    };
  },

  computed: {
    columnWidth: function() {
      return (
        (this.isCartesianChart && this.series.length === 1 ? 78.5 : 89.5) /
          this.columns.length +
        "%"
      );
    },
  },
  watch: {
    chartConfig: function(newProp) {
      this.rebuildData(newProp);
    },
  },

  mounted: function() {
    this.rebuildData(this.chartConfig);
  },

  methods: {
    rebuildData(prop) {
      this.xaxisType = prop.xaxis.type;
      this.isRangeChart = prop.chart.type === "rangeBar";
      this.isBubbleChart = prop.chart.type === "bubble";
      this.series = prop.series;
      this.$_dataMixin_createRecordsFromSeries();
    },
    onClickDataPointConfig(event) {
      if (event.row > 0) {
        this.clickedCellInfo = event;

        this.dataPointFillColor = this.series[event.col - 2].data[
          event.row - 1
        ].fillColor;

        if (this.isCartesianChart) {
          this.showDataPointConfigDialog = true;
        }
      }
    },
    onClickDeleteRow(event) {
      if (event.row > 0) {
        const rowIndexToDel = [event.row - 1];

        if (this.isCartesianChart) {
          this.series.forEach((s, sI) => {
            this.series[sI].data.splice(rowIndexToDel, 1);
          });
        } else {
          this.series.splice(rowIndexToDel, 1);
          this.chartConfig.labels.splice(rowIndexToDel, 1);
        }
        this.updateRecordsAndCharts();
      }
    },
    selectCSVfile() {
      const elInput = this.$refs.attach;
      elInput.click();
      elInput.value = null;
    },
    uploadCSV(e) {
      var file = e.target.files[0];

      this.$papa.parse(file, {
        complete: (results, file) => {
          const ser = this.$_dataMixin_createSeriesFromCSV(results.data);
          if (ser.error) {
            this.$message({
              message: ser.error,
              type: "error",
            });
          } else {
            if (this.isCartesianChart) {
              this.series = ser;
            } else {
              this.series = ser.series;
              this.chartConfig.labels = ser.labels;
            }
            this.updateRecordsAndCharts();
          }
        },
      });

      if (file.size < 20 * 1024 * 1024) {
        this.csvFile = {
          fileName: file.name,
          file: new File([file], file.name, {
            type: file.type,
          }),
        };
      } else {
        this.csvFile = null;
      }
    },

    onRowCreate() {
      if (this.isCartesianChart) {
        if (this.series.length === 0) {
          this.series = [
            {
              data: [],
            },
          ];
        }
        if (this.isRangeChart) {
          this.series[0].data.push({
            x: "",
            y:
              this.chartConfig.xaxis.type === "datetime"
                ? [new Date().getTime(), new Date().getTime()]
                : [0, 10],
          });
        } else if (this.isBubbleChart) {
          this.series[0].data.push({
            x: "",
            y: 10,
            z: 20,
          });
        } else {
          this.series.forEach((s) => {
            s.data.push({
              x: this.xaxisType === "datetime" ? new Date().getTime() : "",
              y: 10,
            });
          });
          if (this.chartType === "bubble") {
            this.series[0].data[this.series[0].data.length - 1].z = 10;
          }
        }
      } else {
        this.chartConfig.labels.push("");
        this.series.push(10);
      }

      this.updateRecordsAndCharts();
    },
    onColCreate() {
      const newSeriesData = this.series[0].data.map((s) => {
        return {
          x: s.x,
          y: null,
        };
      });
      this.series.push({
        name: `series-${this.series.length + 1}`,
        data: newSeriesData,
      });
      this.updateRecordsAndCharts();
    },
    dataPointColorChanged(val) {
      this.dataPointFillColor = val;
    },
    updateDataPointConfig() {
      this.series[this.clickedCellInfo.col - 2].data[
        this.clickedCellInfo.row - 1
      ].fillColor = this.dataPointFillColor;
      this.onCloseDataPointConfig();
      this.updateRecordsAndCharts();
    },
    changeDataValue(changedCell) {
      if (this.isCartesianChart) {
        this.series.forEach((s, sI) => {
          if (changedCell.col === 0) {
            this.series[sI].data[changedCell.row - 1].x = changedCell.value;
          } else {
            if (changedCell.value !== "") {
              if (this.chartType === "bubble") {
                if (changedCell.col === 1) {
                  this.series[0].data[changedCell.row - 1].y =
                    changedCell.value;
                }
                if (changedCell.col === 2) {
                  this.series[0].data[changedCell.row - 1].z =
                    changedCell.value;
                }
              } else {
                this.series[changedCell.col - 1].data[changedCell.row - 1].y =
                  changedCell.value;
              }
            }
          }
        });
        this.updateChartSeries(this.series);
      } else {
        this.series.forEach((s, sI) => {
          if (changedCell.col === 0) {
            this.chartConfig.labels[changedCell.row - 1] = changedCell.value;
          } else {
            this.series[changedCell.row - 1] = parseFloat(changedCell.value);
          }
        });
        this.updateChartOptions({
          series: this.series,
          labels: this.chartConfig.labels,
        });
      }
    },
    saveTimelineValue() {
      if (this.timelineValues && this.timelineValues.length === 2) {
        this.series[0].data[this.clickedCellInfo.row - 1].y = [
          new Date(this.timelineValues[0]).getTime(),
          new Date(this.timelineValues[1]).getTime(),
        ];
        this.clickedCellInfo = null;
      }
      this.updateRecordsAndCharts();
      this.onCloseTimelineValueDialog();
    },
    savexDatetimeValue() {
      this.series.forEach((s, sI) => {
        this.series[sI].data[this.clickedCellInfo.row - 1].x = new Date(
          this.xDatetimeValue
        ).getTime();
      });
      this.updateRecordsAndCharts();
      this.onClosexDatetimeValueDialog();
    },
    saveSeriesName() {
      this.series[this.clickedCellInfo.col - 1].name = this.editSeriesName;
      this.clickedCellInfo = null;
      this.updateRecordsAndCharts();
      this.showSeriesNameDialog = false;
    },
    removeSeries() {
      const index = this.clickedCellInfo.col - 1;

      this.series.splice(index, 1);
      this.updateRecordsAndCharts();
      this.clickedCellInfo = null;
      this.showSeriesNameDialog = false;
    },
    onCloseSeriesNameDialog() {
      this.showSeriesNameDialog = false;
      this.clickedCellInfo = null;
    },
    onCloseTimelineValueDialog() {
      this.showTimelineValueDialog = false;
      this.clickedCellInfo = null;
    },
    onClosexDatetimeValueDialog() {
      this.showXaxisDatetimeDialog = false;
      this.clickedCellInfo = null;
    },
    onCloseDataPointConfig() {
      this.showDataPointConfigDialog = false;
      this.clickedCellInfo = null;
    },
    onClickCell(event, index) {
      if (index > 0) {
        this.clickedCellInfo = event;

        if (this.isRangeChart && event.row > 0) {
          const startEnd = "";
          const row = this.series[0].data[event.row - 1];
          this.showTimelineValueDialog = true;
          this.timelineValues = [
            getDate(row.y[0], true),
            getDate(row.y[1], true),
          ];
        } else {
          if (event.row === 0 && this.isCartesianChart && !this.isRangeChart) {
            const sName = this.series[event.col - 1]?.name;
            this.editSeriesName = sName ? sName : `series-${event.col}`;
            this.showSeriesNameDialog = true;
          }
        }
      } else {
        if (
          index === 0 &&
          event.row > 0 &&
          this.isCartesianChart &&
          !this.isRangeChart &&
          this.xaxisType === "datetime"
        ) {
          this.clickedCellInfo = event;
          this.showXaxisDatetimeDialog = true;
        }
      }
    },
    async updateRecordsAndCharts() {
      if (this.isCartesianChart) {
        await this.updateChartSeries(this.series);
      } else {
        await this.updateChartOptions({
          series: this.series,
          labels: this.chartConfig.labels,
        });
      }
      this.$_dataMixin_createRecordsFromSeries();
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-grid {
  margin-top: 16px;
  background: #343e59;
  height: 100vh;
  width: calc(100% + 13px);
}

.creation {
  margin-top: 7px;
  padding: 7px 0;
  display: flex;
  .el-button {
    padding: 5px 15px;
    &:nth-child(1),
    &:nth-child(2) {
      min-width: 120px;
    }
    > span {
      display: flex;
      align-items: center;
    }
  }
}
</style>
